import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_BROADCASTHISTORY = 'getBroadcastHistory';
export const CREATE_BROADCAST = 'createBroadcast';
export const CANCEL_SCHEDULED_BROADCAST = 'cancelScheduledBroadcast';
// mutations
export const SET_BROADCASTHISTORY = 'setBroadcastHistory';

const recipientType = {
  segment_yesterday_register: 'Registered Yesterday',
  segment_yesterday_transaction: 'Do Transaction Yesterday',
  segment_today_register: 'Registered Today',
  segment_today_transaction: 'Do Transaction Today',
  segment_month_register: 'Registered This Month',
  segment_month_transaction: 'Do Transaction This Month',
  segment_lastmonth_register: 'Registered Last Month',
  segment_lastmonth_transaction: 'Do Transaction Last Month',
  segment_custom_register: 'Custom Register Date',
  segment_custom_transaction: 'Custom Transaction Date',
  csv: 'CSV',
  private: 'Private User',
  public: 'Public User',
  all: 'All User',
};

const state = {
  broadcast_history: {},
};

const getters = {
  broadcast_history(state) {
    return state.broadcast_history;
  },
};

const actions = {
  [GET_BROADCASTHISTORY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/broadcast-notifications', param)
        .then(async ({ data }) => {
          await context.commit(SET_BROADCASTHISTORY, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_BROADCAST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/broadcast-notifications', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [CANCEL_SCHEDULED_BROADCAST]({ commit }, broadcastId) {
    try {
      const response = await ApiService.put(`/api/broadcast-notifications/cancel/${broadcastId}`);
      commit(SET_BROADCASTHISTORY, response.data.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  [SET_BROADCASTHISTORY](state, data) {
    let rows = [];
    if (data.count) {
      try {
        rows = data.rows.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.broadcast_date_str = moment(v.is_scheduled ? v.schedule_date : v.created_at).format('DD MMM YYYY, HH:mm');
          v.recipient_type_str = recipientType[v.recipient_type];
          v.segmentation_info = '';
          if (v.segment_params.start_date && v.segment_params.end_date) {
            v.segmentation_info = `Broadcast to ${v.recipient_type_str} from ${moment(v.segment_params.start_date).format('DD MMM YYYY')} to ${moment(v.segment_params.end_date).format('DD MMM YYYY')}`;
          }
          return v;
        });
      } catch (error) {
        console.log(error);
      }
    }
    data.rows = rows;
    state.broadcast_history = data;
  },
  [CANCEL_SCHEDULED_BROADCAST](state, broadcastId) {
    const canceledBroadcast = state.broadcast_history.rows.find(item => item._id === broadcastId);
    if (canceledBroadcast) {
      canceledBroadcast.isCanceled = true;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
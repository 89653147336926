import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth';
import vouchers from './modules/vouchers';
import merchants from './modules/merchants';
import rewards from './modules/rewards';
import academys from './modules/academys';
import missions from './modules/missions';
import activitys from './modules/activitys';
import socials from './modules/socials';
import app_users from './modules/app_user';
import promo from './modules/promo';
import transactions from './modules/transactions';
import categorys from './modules/categorys';
import roles from './modules/roles';
import internal_users from './modules/internal_users';
import broadcast from './modules/broadcast';
import companies from './modules/companies';
import disbursement from './modules/disbursement';
import mybenefit from './modules/mybenefit';
import appcontent from './modules/app_content';
import refund_request from './modules/refund_request';
import ewa from './modules/ewa';
import topups from './modules/topups';
import ppob from './modules/ppob';
import lms from './modules/lms';
import coin from './modules/coin';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    vouchers,
    merchants,
    rewards,
    academys,
    missions,
    activitys,
    socials,
    app_users,
    promo,
    transactions,
    categorys,
    roles,
    internal_users,
    broadcast,
    companies,
    disbursement,
    mybenefit,
    appcontent,
    refund_request,
    ewa,
    topups,
    ppob,
    lms,
    coin
  },
  plugins: [createPersistedState()],
});

import ApiService from './api.service';

// actions
export const GET_COIN_ALL = 'getAllCoin';
export const GET_COIN_HISTORY = 'getCoinHistory';

// mutations
export const SET_ALL_COIN = 'setAllCoin';
export const SET_HISTORY_COIN = 'setHistoryCoin';


const state = {
  coins: {},
};

const getters = {
    coins(state) {
    return state.coins; 
  },
};

const actions = {
    [GET_COIN_HISTORY](context, { owned_by, page, query }) {
        return new Promise((resolve, reject) => {
        ApiService.get(`api/coin/history?page=${page}&owned_by=${owned_by}`, query)
            .then(({ data }) => {
            resolve(data);
            })
            .catch((err) => {
            reject(err);
            });
        });
    },

    // [GET_COIN_ALL](context, { page, query }) {
    //     return new Promise((resolve, reject) => {
    //     ApiService.get(`api/coin/history?page=${page}&owned_by=-1`, query)
    //         .then(({ data }) => {
    //         resolve(data);
    //         })
    //         .catch((err) => {
    //         reject(err);
    //         });
    //     });
    // },
    [GET_COIN_ALL](context, { page, query }) {
      return new Promise((resolve, reject) => {
      ApiService.get(`api/coin/history?page=${page}&owned_by=-1`, query)
          .then(async ({ data }) => {
          await context.commit(SET_ALL_COIN, data.data);
          resolve(data);
          })
          .catch((err) => {
          reject(err);
          });
      });
    },
};

const mutations = {
  [SET_ALL_COIN](state, data) {
    state.coins = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};